// @import './template/subViews/AddProgram/addProgram.scss';
// @import './template/subViews/AddSponsor/addSponsor.scss';
// @import './template/subViews/ProgramList/programList.scss';
// @import './template/subViews/SponsorList/sponsorList.scss';
// @import './template/subViews/AddBanner/addBanner.scss';
// @import './template/subViews/BannerList/bannerList.scss';
// @import './template/subViews/AddNews/addNews.scss';
// @import './template/subViews/AddSupplier/addSupplier.scss';

@import '../Banner/banner.scss';
@import '../News/news.scss';
@import '../Supplier/supplier.scss';
@import '../Program/program.scss';
.home {
    font-family: Lato, sans-serif;
    background-color: $bg-gray;
    height: 100vh;
    display: flex;
    flex-direction: column;

    &-body {
        display: flex;
        flex: 1;

        main {
            padding: 16px;
            flex: 1;

            .main-content {
                width: 100%;
            }
        }
    }
}

.dialog {
    font-family: Lato, sans-serif;

    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $gray-6;
        padding: 10px 16px;

        &__close {
            border: none;
            outline: none;
            background-color: transparent;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: $gray-5;
                }
            }
        }

        span {
            font-family: Lato, sans-serif;
            font-size: 17px;
        }
    }

    .dialog-content {
        padding: 16px 24px;

        .add-program {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 16px;

            > .MuiFormControl-root {
                width: 100%;

                input {
                    width: 100%;
                }
            }

            .form-buttons {
                button {
                    padding: 13px 35px;
                    border-radius: 8px;
                }
            }
        }
    }
}

.ant-picker-time-panel-column::after {
    content: none !important;
}
