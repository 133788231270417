$black: #000000;
$black-1: #333;
$black-2: #202121;
$blue: #306fb9;
$blue-1: #4193e0;
$blue-2: #ddf5fd;
$blue-3: #04acf7;
$bg-blue: #dcecfc;
$bg-blue-button: #dbe2fc;
$hover-blue-pale: rgba(25, 118, 210, 0.04);
$gray: #9e9e9e;
$gray-1: #eee;
$gray-2: #989797;
$gray-3: #c3c9d0;
$gray-4: #b3b3b3;
$gray-5: #666666;
$gray-6: #ecf0fe;
$bg-gray: #fafafa;
$white: #ffffff;
$border-gray: #e7e7e7;
$border-gray-1: #e6e6e6;
$red-button: #db1f21;
$bg-red-button: #fcedec;
$success: #66bd50;
$success-1: #008359;
$scrollbar-track: rgba(230, 230, 230, 0.6);
$border-gray: #ccc;
//------FONT-WEIGHT------
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
