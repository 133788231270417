.custom-breadcrumb {
    padding: 8px 0;
    min-height: 50px;
    font-family: Lato, sans-serif;

    .MuiBreadcrumbs-li {
        .breadcrumb-item {
            cursor: pointer;
            color: $gray;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 13px;

            &.last-item {
                color: $black-1;
            }
        }
    }
}
