.custom-tableHead {
    background-color: $blue-2;

    &__cell {
        white-space: nowrap;
        color: $black-2;
        font-family: Lato, sans-serif;
        background-color: $blue-2;
        padding: 12px 8px;
        font-size: 15px;

        &::first-letter {
            text-transform: capitalize;
        }
    }
}