.loader-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(60, 60, 60, 0.4);
    position: fixed;
    z-index: 10520;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loader-gif {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        > img {
            width: 100%;
            height: 100%;
        }
    }
}
