.group-input {
    position: relative;
    font-family: Lato, sans-serif;
    height: 100%;

    fieldset {
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        height: 100%;
        max-height: 48px;

        &.hover {
            border-color: rgba(0, 0, 0, 0.87);
        }

        &.focus {
            border-color: $blue-1;

            legend {
                span {
                    color: $blue-1;
                }
            }
        }

        legend {
            position: absolute;
            top: -22px;
            left: 10px;

            > span {
                font-size: 13px;
                color: $black-1;
                padding: 0 5px;
                background-color: $white;
                display: inline-block;
            }
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        padding: 12.5px 13px;
        display: flex;
        align-items: center;
        gap: 8px;

        .input-item {
            width: 50%;
            border: 0;
            border-color: transparent;
            outline: none;
            padding: 0;
            margin: 0;
            font-size: 15px;

            &::placeholder {
                color: $gray;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &[type='number'] {
                -moz-appearance: textfield;
            }
        }

        .input-divider {
            width: 2px;
            height: 100%;
            background-color: $gray-3;
        }
    }

    .ant-picker-focused {
        border: 0;
        border-color: transparent;
        box-shadow: none;
    }
}
