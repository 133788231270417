.custom-datepicker {
    width: 100%;
    position: relative;
    font-family: Lato, sans-serif;

    > span {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0 5px;
        color: $black-2;
        z-index: 10;
        background-color: $white;
        font-size: 13px;
    }

    &__input {
        width: 100%;
        padding: 12.5px 14px;
    }
}
