.preview-image {
    width: 64px;
    height: 64px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }

    &__wrapper {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        transition: background-color 0.1s linear;
        border-radius: 4px;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.4);

            svg {
                display: block;
            }
        }

        svg {
            cursor: pointer;
            display: none;

            path {
                fill: $border-gray;
            }
        }
    }
}
