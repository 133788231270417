.add-news {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px 16px;

    > .MuiFormControl-root {
        width: 49%;

        input {
            width: 100%;
        }
    }

    .form-buttons {
        button {
            padding: 13px 35px;
            border-radius: 8px;
        }
    }
}
