$screen-xs-max: 400px;
$screen-sm-max: 576px;
$screen-md-max: 768px;
$screen-lg-max: 992px;
$screen-xxl-up: 1200px;
$screen-largest: 1400px;

@mixin for-mobile-down {
    @media (max-width: #{$screen-xs-max}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$screen-sm-max +1}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$screen-md-max + 1}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$screen-lg-max + 1}) {
        @content;
    }
}

@mixin large-desktop {
    @media (max-width: #{$screen-xxl-up + 1}) {
        @content;
    }
}

@mixin largest {
    @media (max-width: #{$screen-largest + 1}) {
        @content;
    }
}
