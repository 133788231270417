.header {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 3px 1px rgba(186, 186, 186, 0.15), 0 1px 2px 0 rgba(22, 22, 22, 0.3);
    padding: 16px;

    &-logo {
    }

    &-container {
        display: flex;
        align-items: center;
        gap: 24px;

        &__search {
            flex: 1;

            input {
                width: 100%;
            }
        }

        &__noti {
            position: relative;

            &[data-exist='true']::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $red-button;
            }
        }

        &__avatar {
            width: 48px;
            height: 48px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
}
