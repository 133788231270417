.wasvalidated {
    .customInput {
        &-valid {
            .MuiFormLabel-root {
                color: $success;
            }

            fieldset.MuiOutlinedInput-notchedOutline {
                border: 1px solid $success;
                border-radius: 4px;
            }
        }

        &-invalid {
            .MuiFormLabel-root {
                color: $red-button;
            }

            fieldset.MuiOutlinedInput-notchedOutline {
                border: 1px solid $red-button;
                border-radius: 4px;
            }
        }
    }

    .form-helper-text {
        display: inline-block;
        color: $red-button;
        font-size: 13px;
        position: absolute;
        bottom: -25px;
        left: 0;
    }
}

.customInput {
    label {
        font-family: inherit;
        color: $black-2;

        &.Mui-focused {
            color: $blue-1;
        }
    }

    &.validate-input {
        &.customInput-invalid {
            .MuiFormLabel-root {
                color: $red-button;
            }

            fieldset.MuiOutlinedInput-notchedOutline {
                border: 1px solid $red-button;
                border-radius: 4px;
            }
        }
    }

    .MuiInputBase-root {
        font-family: Lato, sans-serif;

        &.Mui-focused {
            fieldset {
                border: 1px solid $blue-1;
            }
        }

        input {
            padding: 12.5px 14px;

            &.MuiInputBase-inputSizeSmall {
                padding: 4px 0 5px;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &[type='number'] {
                -moz-appearance: textfield;
            }

            @include mobile {
                font-size: 13px !important;
            }
        }
    }

    &-startIcon {
        input {
            padding-left: 0 !important;
        }
    }

    &-disabled {
        .MuiFormLabel-root {
            color: $black-2;
        }
    }

    &--hideFieldset {
        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }

    .MuiInputAdornment-positionEnd {
        p {
            color: $gray;
        }
    }

    .svg-gray {
        svg {
            path {
                fill: $gray-4;
            }
        }
    }
}
