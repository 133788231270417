.sidebar {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    background-color: $white;
    padding: 16px 0;
    border-top: solid 1px $gray-1;
    height: 100%;
    min-width: 356px;

    &-block {
        &__title {
            margin: 0;
            height: 43px;
            line-height: 43px;
            padding: 0 16px;
            font-size: 19px;
            font-weight: 500;
            color: $blue-1;
            margin-bottom: 16px;
        }

        &__items {
            .sidebar-item {
                height: 48px;
                line-height: 48px;
                color: $blue-1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;
                cursor: pointer;
                margin-bottom: 16px;
                transition: background-color 0.1s linear;

                svg {
                    width: 16px;
                    height: 16px;
                    color: $blue-1;
                }

                &:hover {
                    background-color: $bg-blue;
                }

                &--active {
                    background-color: $blue-1 !important;
                    color: $white;

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}
