@import 'constants.scss';
@import 'custom.scss';
@import 'react-toastify/dist/ReactToastify.css';

body {
    font-size: 14px;
    font-weight: $font-weight-normal;
}

//--------------MARGIN-----------------

.mt-15px {
    margin-top: 15px;
}

.mt-25px {
    margin-top: 25px;
}

@include mobile {
    .mt-sm-15px {
        margin-top: 15px !important;
    }
}

@include mobile {
    .mt-sm-20px {
        margin-top: 20px !important;
    }
}

@include mobile {
    .mt-sm-25px {
        margin-top: 25px !important;
    }
}

@include for-mobile-down {
    .mt-xs-15px {
        margin-top: 15px !important;
    }
}

@include for-mobile-down {
    .mt-xs-20px {
        margin-top: 20px !important;
    }
}

@include for-mobile-down {
    .mt-xs-25px {
        margin-top: 25px !important;
    }
}

//--------------PADDING-----------------

//--------------FONT--------------------

.fw-500 {
    font-weight: 500;
}

//--------------LINE HEIGHT-------------

//--------------POSITION----------------

@include mobile {
    .position-sm-static {
        position: static !important;
    }
}

//------------OTHERS------------------
.cursor-pointer {
    cursor: pointer;
    pointer-events: auto;
}

@include for-mobile-down {
    .position-xs-sticky {
        position: sticky !important;
    }
}

//--------------BORDER----------------

.border-left {
    border-left: 1px solid $border-gray;
}

.border-bottom {
    border-bottom: 1px solid $border-gray;
}

@include mobile {
    .border-sm-none {
        border: none !important;
    }
}

@include for-mobile-down {
    .position-xs-sticky {
        position: sticky !important;
    }
}

//--------------CUSTOM CLASSNAME----------------

.text-required {
    color: $red-button !important;
}

//----------------------GAP---------------------

.gap-10px {
    gap: 10px;
}

input {
    font-size: 15px !important;
    color: $black-2 !important;

    &::placeholder {
        font-size: 15px !important;
        color: $black-2 !important;
        opacity: 0.42 !important;
        font-family: Lato, sans-serif !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
}

::-webkit-scrollbar {
    width: 4px;

    /* Track */
    &-track {
        background: $scrollbar-track;
    }

    /* Handle */
    &-thumb {
        background-color: $border-gray;
        border-radius: 20px;

        /* Handle on hover */
        &:hover {
            background: $gray-2;
        }
    }
}
