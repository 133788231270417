.add-sponsor {
    &__row {
        >.MuiFormControl-root {
            width: 33%;

            input {
                width: 100%;
            }
        }
    }

    .form-buttons {
        button {
            padding: 13px 35px;
            border-radius: 8px;
        }
    }
}

.justify-content-space-around {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 24px 16px;
}
.program-table {
    border-radius: 8px;
    border: 1px solid $blue-1;

    .table-pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
    }
}
.checkbox-label{
    width: 'max-content';
    height: 50;
    display: flex;
    align-items: center;
    margin-left: 15;
}