.custom-btn {
    border: none;
    outline: 0;
    padding: 8px 15px;
    border-radius: 4px;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    isolation: isolate;
    z-index: 10;

    &__lg {
        height: 48px;
    }

    &__md {
        height: 40px;
    }

    &__sm {
        height: 32px;
    }

    &__contained {
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }

        &.custom-btn--primary {
            background-color: $blue-1;
        }

        &.custom-btn--danger {
            background-color: $red-button;
        }
        &.custom-btn--normal {
            background-color: $white;
            border-width: 1px solid;
        }
    }

    &__half-contained {
        &.custom-btn--primary {
            background-color: $bg-blue;
            color: $blue-1;

            &:hover {
                background-color: $bg-blue-button;
            }
        }
    }

    &__outlined {
        background-color: $white;

        &.custom-btn--primary {
            color: $blue-1;
            border: 1px solid $blue-1;

            &:hover {
                background-color: $hover-blue-pale;
            }
        }

        &.custom-btn--danger {
            color: $red-button;
            border: 1px solid $red-button;

            &:hover {
                background-color: $bg-red-button;
            }
        }
    }

    &__text {
        background-color: transparent;

        &.custom-btn--primary {
            color: $blue-1;

            &:hover {
                background-color: $hover-blue-pale;
            }
        }
    }

    &--disabled {
        color: $gray-3 !important;
        opacity: 1 !important;
        pointer-events: none;

        &.custom-btn__contained {
            background-color: $border-gray-1 !important;
        }
    }

    &__text {
        background-color: transparent;

        &.custom-btn--primary {
            color: $blue-1;

            &:hover {
                background-color: $hover-blue-pale;
            }
        }

        &.custom-btn--danger {
            color: $red-button;

            &:hover {
                background-color: $bg-red-button;
            }
        }
    }
}
