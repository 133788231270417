.MuiFormControl-root {
    font-family: Lato, sans-serif;

    label {
        color: $black-2;
    }

    .custom-select {
        .MuiSelect-select {
            padding: 12.5px 14px;
        }

        &.Mui-focused {
            fieldset {
                border: 1px solid $blue-1 !important;

                > span {
                    padding-right: 10px;
                }
            }
        }

        fieldset {
            legend {
                > span {
                    padding-right: 14px !important;
                }
            }
        }
    }
}

.MuiMenu-list {
    max-height: 300px;
    overflow-y: auto;
}
